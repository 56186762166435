<script setup lang="ts">
  import type { PloneTile } from '~/types/Plone'

  const { notify } = useBugsnag()

  const props = defineProps<{ tiles: PloneTile[] }>()

  const route = useRoute()

  const tileTypes = [
    'cards',
    'carousel',
    'projects',
    'events',
    'headline',
    'links',
    'news',
    'competitions',
    'richtext',
    'listing',
    'imagetiles',
    'calltoaction',
    'contactinfo',
    'profiles',
    'collapsible',
    'publications',
    'gallery',
    'embed',
  ]

  const tileComponentMapping = {
    projects: 'carousel',
    publications: 'listing',
  }

  function getTileComponent (tile: PloneTile) {
    if (tileComponentMapping[tile.tile_type]) {
      return tileComponentMapping[tile.tile_type]
    }

    return tile.tile_type
  }

  const clientOnlyTiles = ['projects']

  function isClientOnly (tile: PloneTile) {
    return clientOnlyTiles.includes(tile.tile_type)
  }

  function getSpacing (tile: PloneTile, index) {
    const nextTile: PloneTile = props.tiles?.[index + 1]
    if (tile.tile_type === 'profiles'
      && tile.profile_template === 'collapsible'
      && nextTile?.profile_template === 'collapsible'
      && tile.tile_type === nextTile?.tile_type) {
      return 400
    }
    return 600
  }

  function onError (e) {
    console.error(e)
    notify(e)
  }
</script>

<template>
  <div class="app-tiles">
    <template
      v-for="(tile, index) in tiles"
      :key="tile.id">
      <NuxtErrorBoundary @error="onError">
        <template v-if="tileTypes.includes(tile.tile_type)">
          <client-only v-if="isClientOnly(tile)">
            <component
              v-if="tileTypes.includes(tile.tile_type)"
              :data="tile"
              :class="`u-mb--${getSpacing(tile, index)}`"
              :is="`tile-${getTileComponent(tile)}`"/>
          </client-only>
          <component
            v-else
            :data="tile"
            :class="`u-mb--${getSpacing(tile, index)}`"
            :is="`tile-${getTileComponent(tile)}`"/>
        </template>
        <app-container
          v-else
          narrow
          class="u-mb--600">
          <app-alert>
            Komponenta "{{ tile.tile_type }}" není implementována
          </app-alert>
        </app-container>

        <template #error="{ error }">
          <app-container
            narrow
            class="u-mb--600">
            <app-alert
              error
              dismissable>
              Vyskytla se chyba, zkuste prosím <a :href="route.path" class="u-c--white">obnovit stránku</a>.
            </app-alert>
          </app-container>
        </template>
      </NuxtErrorBoundary>
    </template>
  </div>
</template>
